<template>
  <v-card>
    <v-card-title>
      <v-btn
        v-show="selectedTasks.length"
        class="mr-6"
        icon
        color="error"
        @click="tryDeleteManyTasks(selectedTasks)"
      >
        <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
      </v-btn>
      <v-btn
        v-model="showFilters"
        :color="isFiltered ? 'primary': 'default'"
        icon
        plain
        @click="() =>{
          showFilters = !showFilters
        }"
      >
        <v-icon>{{ icons.mdiFilter }}</v-icon>
      </v-btn>
      <v-spacer />
      <AddNewTask
        @submitted="tryFetchTasks"
      />
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="selectedTasks"
        hide-default-footer
        :mobile-breakpoint="0"
        item-key="id"
        loading-text="Loading tasks ..."
        :server-items-length="total"
        :loading="loading"
        :items="tasks"
        :headers="taskHeaders"
        :options.sync="tableOptions"
        :footer-props="{
          'items-per-page-options': pages,
          'show-first-last-page': true,
          'show-current-page': true,
        }"
      >
        <template
          v-slot:item.account="{value}"
        >
          {{ value && value.phone }}
        </template>
        <template
          v-slot:item.message="{value}"
        >
          {{ value && value.text }}
        </template>
        <template
          v-slot:top
        >
          <v-layout
            v-show="showFilters"
          >
            <v-row>
              <v-col cols="3">
                <SelectAccount v-model="tableOptions.account" />
              </v-col>
              <v-col cols="3">
                <SelectMessage v-model="tableOptions.message" />
              </v-col>
              <v-col cols="3">
                <SelectGroup v-model="tableOptions.groups" />
              </v-col>
              <v-col cols="3">
                <v-select
                  v-model="tableOptions.enable"
                  :items="enabledOptions"
                  clearable
                  label="Enable"
                ></v-select>
              </v-col>
            </v-row>
          </v-layout>
        </template>
        <!-- SELECT ROW-->
        <template v-slot:header.data-table-select="{ props, on }">
          <CustomSelectRows
            :value="props"
            :page="tableOptions.page"
            :total="total"
            v-on="on"
          />
        </template>
        <template v-slot:item.groups="{ value }">
          {{ value && value.name }}
        </template>
        <template
          v-slot:item.schedule="{ value }"
        >
          {{ value }}
        </template>
        <template v-slot:item.enable="{ value, item }">
          <v-layout
            d-flex
            justify-end
          >
            <v-switch
              v-model="value"
              readonly
              @dblclick.stop
              @click="() => {
                item.enable? $message.info('Task enable turned off'):$message.info('Task enable turned on')
                tryUpdateTask({
                  ...item,
                  account: item.account && item.account.id,
                  message: item.message && item.message.id,
                  groups: item.groups && item.groups.id,
                  enable: !item.enable
                })}"
            ></v-switch>
          </v-layout>
        </template>
        <template
          v-slot:item.action="{ item }"
        >
          <TableActions
            :loading="item.loading"
            @edit="openTaskEditModal(item)"
            @delete="tryDeleteTask(item.id)"
          />
        </template>
        <template v-slot:footer="{ props, on }">
          <TableFooter
            v-bind="props"
            v-on="on"
          />
        </template>
      </v-data-table>
    </v-card-text>

    <TaskEditModal
      ref="editTaskModal"
    />

    <ConfirmDialog
      ref="deleteTaskConfirm"
      max-width="400"
    >
      <template v-slot="{agree, cancel}">
        <v-card>
          <v-card-title>Delete task</v-card-title>
          <v-card-text>
            Do you really want to delete task
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="error"
              @click="agree"
            >
              <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
              Delete
            </v-btn>
            <v-btn
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-card>
</template>

<script>
import {
  mdiDeleteForever,
  mdiDotsVertical,
  mdiPencil,
  mdiMagnify,
  mdiFilter,
} from '@mdi/js'
import pick from 'lodash/pick'
import _cloneDeep from 'lodash/cloneDeep'
import { mapActions, mapMutations, mapState } from 'vuex'
import store from '@/store'
import telegramStoreModule, { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewTask from '@/components/telegram/task/AddNew.vue'
import TaskEditModal from '@/components/telegram/task/EditModal.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/TableActions.vue'
import SelectAccount from '@/components/telegram/account/SelectAccount.vue'
import SelectMessage from '@/components/telegram/message/SelectMessage.vue'
import SelectGroup from '@/components/telegram/group/SelectGroup.vue'
import TableFooter from '@/components/TableFooter.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewTask,
    TaskEditModal,
    ConfirmDialog,
    TableActions,
    SelectAccount,
    SelectMessage,
    SelectGroup,
    TableFooter,
  },
  data() {
    return {
      loading: true,
      total: 50,
      showFilters: false,
      tableOptions: {
        account: +(this.$route.query.account || 0),
        message: +(this.$route.query.message || 0),
        enable: this.$route.query.enable || '',
        groups: +(this.$route.query.groups || 0),
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedTasks: [],
      pages: [50, 100, 200],
      enabledOptions: [{
        text: 'Enabled',
        value: 'true',
      }, {
        text: 'Disabled',
        value: 'false',
      }],
      icons: {
        mdiDotsVertical,
        mdiDeleteForever,
        mdiPencil,
        mdiFilter,
        mdiMagnify,
      },
      taskHeaders: [
        {
          text: 'Account',
          value: 'account',
          sortable: true,
          filterable: true,
          align: 'start',
          width: '25%',
        },
        {
          text: 'Message',
          value: 'message',
          cellClass: 'py-2',
          sortable: true,
          align: 'start',
          width: '25%',
        },
        {
          text: 'Groups',
          value: 'groups',
          sortable: true,
          align: 'start',
          width: '20%',
        },
        {
          text: 'Schedule',
          value: 'schedule',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Enable',
          value: 'enable',
          sortable: true,
          align: 'end',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      tasks(state) {
        return state[TELEGRAM_MODULE_NAME]?.task.tasks
      },
    }),
    isFiltered() {
      return this.showFilters || this.checkFilterToggle()
    },
    mappedOptions() {
      return this.$route.query
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status', 'account', 'message', 'groups', 'enable'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.tryFetchTasks(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },

  mounted() {
    // Register module
    if (!store.hasModule(TELEGRAM_MODULE_NAME)) {
      store.registerModule(TELEGRAM_MODULE_NAME, telegramStoreModule)
    }
  },

  methods: {
    ...mapActions({
      fetchTasks(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/fetchTasks`, payload)
      },
      updateTask(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateTask`, payload)
      },
      removeTask(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/removeTask`, payload)
      },
    }),

    ...mapMutations({
      commitSetTasks(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/SET_TASKS `, payload)
      },
      commitEditTask(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_TASK`, payload)
      },
      commitDeleteTask(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/REMOVE_TASK`, payload)
      },
    }),

    async tryFetchTasks(params = this.mappedOptions) {
      this.loading = true
      const { data } = await this.fetchTasks(params)
      this.total = data.total
      this.loading = false
    },

    async tryDeleteTask(id) {
      this.$refs.deleteTaskConfirm.open().then(() => {
        this.commitEditTask({ id, loading: true })
        this.removeTask({ id }).then(() => {
          this.commitDeleteTask({ id })
          this.$message.success('Task deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateTask(value) {
      this.commitEditTask({ ...value, loading: true })
      const { data } = await this.updateTask({ task: value })
      this.commitEditTask(data)
    },

    openTaskEditModal(task) {
      this.$refs.editTaskModal.open(task)
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
  },
}
</script>
