<template>
  <v-autocomplete
    v-model="localValue"
    deletable-chips
    :loading="loading"
    :disabled="disabled"
    :items="localItems"
    :search-input.sync="search"
    clearable
    chips
    cache-items
    hide-no-data
    hide-details
    :rules="rules"
    label="Select message"
  ></v-autocomplete>
</template>

<script>
import { mapState } from 'vuex'
import TelegramApi from '@core/api/telegram'
import { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

export default {
  props: {
    disabled: Boolean,
    value: {
      type: [Array, Number],
      default: null,
    },
    rules: { type: Array, default: () => [] },
  },
  data() {
    return {
      loading: false,
      items: null,
      search: null,
    }
  },
  computed: {
    ...mapState({
      stateMessages(state) {
        return state[TELEGRAM_MODULE_NAME]?.messages?.map(({ value: { text, id } }) => ({ text, value: id }))
      },
    }),
    localItems() {
      return this.items || this.stateMessages
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      this.tryFetchGroups(val)
    },
  },
  mounted() {
    if (!this.stateProxies?.length) {
      this.tryFetchGroups()
    }
  },
  methods: {
    async tryFetchGroups(search) {
      this.loading = true
      await TelegramApi.Messages.fetchMessages({ q: search }).then(({ data }) => {
        this.items = data.items.map(({ text, id }) => ({ text, value: id }))
      })
      this.loading = false
    },
  },
}
</script>

<style>

</style>
