var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedTasks.length),expression:"selectedTasks.length"}],staticClass:"mr-6",attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.tryDeleteManyTasks(_vm.selectedTasks)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))])],1),_c('v-btn',{attrs:{"color":_vm.isFiltered ? 'primary': 'default',"icon":"","plain":""},on:{"click":function () {
        _vm.showFilters = !_vm.showFilters
      }},model:{value:(_vm.showFilters),callback:function ($$v) {_vm.showFilters=$$v},expression:"showFilters"}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiFilter))])],1),_c('v-spacer'),_c('AddNewTask',{on:{"submitted":_vm.tryFetchTasks}})],1),_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"item-key":"id","loading-text":"Loading tasks ...","server-items-length":_vm.total,"loading":_vm.loading,"items":_vm.tasks,"headers":_vm.taskHeaders,"options":_vm.tableOptions,"footer-props":{
        'items-per-page-options': _vm.pages,
        'show-first-last-page': true,
        'show-current-page': true,
      }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item.account",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.phone)+" ")]}},{key:"item.message",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.text)+" ")]}},{key:"top",fn:function(){return [_c('v-layout',{directives:[{name:"show",rawName:"v-show",value:(_vm.showFilters),expression:"showFilters"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"3"}},[_c('SelectAccount',{model:{value:(_vm.tableOptions.account),callback:function ($$v) {_vm.$set(_vm.tableOptions, "account", $$v)},expression:"tableOptions.account"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('SelectMessage',{model:{value:(_vm.tableOptions.message),callback:function ($$v) {_vm.$set(_vm.tableOptions, "message", $$v)},expression:"tableOptions.message"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('SelectGroup',{model:{value:(_vm.tableOptions.groups),callback:function ($$v) {_vm.$set(_vm.tableOptions, "groups", $$v)},expression:"tableOptions.groups"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"items":_vm.enabledOptions,"clearable":"","label":"Enable"},model:{value:(_vm.tableOptions.enable),callback:function ($$v) {_vm.$set(_vm.tableOptions, "enable", $$v)},expression:"tableOptions.enable"}})],1)],1)],1)]},proxy:true},{key:"header.data-table-select",fn:function(ref){
      var props = ref.props;
      var on = ref.on;
return [_c('CustomSelectRows',_vm._g({attrs:{"value":props,"page":_vm.tableOptions.page,"total":_vm.total}},on))]}},{key:"item.groups",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value && value.name)+" ")]}},{key:"item.schedule",fn:function(ref){
      var value = ref.value;
return [_vm._v(" "+_vm._s(value)+" ")]}},{key:"item.enable",fn:function(ref){
      var value = ref.value;
      var item = ref.item;
return [_c('v-layout',{attrs:{"d-flex":"","justify-end":""}},[_c('v-switch',{attrs:{"readonly":""},on:{"dblclick":function($event){$event.stopPropagation();},"click":function () {
              item.enable? _vm.$message.info('Task enable turned off'):_vm.$message.info('Task enable turned on')
              _vm.tryUpdateTask(Object.assign({}, item,
                {account: item.account && item.account.id,
                message: item.message && item.message.id,
                groups: item.groups && item.groups.id,
                enable: !item.enable}))}},model:{value:(value),callback:function ($$v) {value=$$v},expression:"value"}})],1)]}},{key:"item.action",fn:function(ref){
              var item = ref.item;
return [_c('TableActions',{attrs:{"loading":item.loading},on:{"edit":function($event){return _vm.openTaskEditModal(item)},"delete":function($event){return _vm.tryDeleteTask(item.id)}}})]}},{key:"footer",fn:function(ref){
              var props = ref.props;
              var on = ref.on;
return [_c('TableFooter',_vm._g(_vm._b({},'TableFooter',props,false),on))]}}]),model:{value:(_vm.selectedTasks),callback:function ($$v) {_vm.selectedTasks=$$v},expression:"selectedTasks"}})],1),_c('TaskEditModal',{ref:"editTaskModal"}),_c('ConfirmDialog',{ref:"deleteTaskConfirm",attrs:{"max-width":"400"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var agree = ref.agree;
              var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Delete task")]),_c('v-card-text',[_vm._v(" Do you really want to delete task ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"},on:{"click":agree}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteForever))]),_vm._v(" Delete ")],1),_c('v-btn',{on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }