<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Account</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectAccount
          v-model="account"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Message</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectMessage
          v-model="message"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Schedule</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="days"
              dense
              label="Days"
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="hours"
              label="Hours"
              dense
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="minutes"
              label="Minutes"
              dense
              placeholder="test"
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-card-subtitle
            v-if="isErrored"
            class="pl-2 py-0 red--text"
          >
            Schedule must contain values other than 0
          </v-card-subtitle>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Group</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectGroup
          v-model="groups"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text"></label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-switch
          v-model="enable"
          label="Enable"
        />
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { simpleRequired } from '@core/utils/validation'

import SelectAccount from '@/components/telegram/account/SelectAccount.vue'
import SelectMessage from '../message/SelectMessage.vue'
import SelectGroup from '../group/SelectGroup.vue'

export default {
  components: {
    SelectAccount,
    SelectMessage,
    SelectGroup,
  },
  props: {
    loading: Boolean,
    task: {
      type: Object,
      default: () => ({}),
    },
    isSubmitted: Boolean,
  },
  data() {
    return {
      isErrored: false,
      isTouched: false,
    }
  },
  watch: {
    days() {
      this.setError()
    },
    hours() {
      this.setError()
    },
    minutes() {
      this.setError()
    },
  },
  methods: {
    setError() {
      if (this.isTouched) {
        if ((this.hours === '00' || this.hours === '0') && (this.minutes === '00' || this.minutes === '0')
      && (this.days === '00' || this.days === '0')) {
          this.isErrored = true
        } else {
          this.isErrored = false
        }
      }
    },
    setTouched() {
      this.isTouched = true
      this.setError()
    },
  },
  setup({ task }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }

    const account = ref(task.account?.id)
    const message = ref(task.message?.id)
    const groups = ref(task.groups?.id)
    const [_days, _hours, _mins] = task.schedule.split(':')
    const hours = ref(_hours || 0)
    const minutes = ref(_mins || 0)
    const days = ref(_days || 0)
    const enable = ref(task.enable)

    const submit = async () => ({
      id: task.id,
      account: account.value,
      message: message.value,
      groups: groups.value,
      schedule: `${days.value}:${hours.value}:${minutes.value}`,
      enable: enable.value,
    })

    return {
      form,
      account,
      groups,
      message,
      hours,
      minutes,
      enable,
      days,
      validate,
      reset,
      simpleRequired,
      submit,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
