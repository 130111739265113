<template>
  <v-form ref="form">
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Account</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectAccount
          v-model="account"
          :disabled="loading"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Message</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectMessage
          v-model="message"
          :disabled="loading"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Schedule</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="days"
              :disabled="loading"
              dense
              label="Days"
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="hours"
              label="Hours"
              :disabled="loading"
              dense
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-col
            cols="12"
            md="4"
          >
            <v-text-field
              v-model.number="minutes"
              label="Minutes"
              :disabled="loading"
              dense
              placeholder="test"
              type="number"
              :rules="[isErrored?'':true]"
              @blur="setTouched"
            ></v-text-field>
          </v-col>
          <v-card-subtitle
            v-if="isErrored"
            class="pl-2 py-0 red--text"
          >
            Schedule must contain values other than 0
          </v-card-subtitle>
        </v-row>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
        <label for="text">Group</label>
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <SelectGroup
          v-model="groups"
          :disabled="loading"
          :rules="[simpleRequired]"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        md="3"
      >
      </v-col>

      <v-col
        cols="12"
        md="9"
      >
        <v-switch
          v-model="enable"
          :disabled="loading"
          label="Enable"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        offset-md="3"
        cols="9"
      >
        <v-btn
          color="primary"
          :loading="loading"
          @click="setTouched(),setError(),submit()"
        >
          Create
        </v-btn>
        <v-btn
          type="reset"
          class="mx-2"
          :disabled="loading"
          outlined
          @click="reset"
        >
          Reset
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiAlertCircleOutline, mdiCalendar, mdiCheck } from '@mdi/js'
import { simpleRequired } from '@core/utils/validation'

import SelectAccount from '@/components/telegram/account/SelectAccount.vue'
import SelectMessage from '../message/SelectMessage.vue'
import SelectGroup from '../group/SelectGroup.vue'

export default {
  components: {
    SelectAccount,
    SelectMessage,
    SelectGroup,
  },
  props: {
    loading: Boolean,
  },
  data() {
    return {
      isErrored: false,
      isTouched: false,
    }
  },
  watch: {
    days() {
      this.setError()
    },
    hours() {
      this.setError()
    },
    minutes() {
      this.setError()
    },
  },
  methods: {
    setError() {
      if (this.isTouched) {
        if ((this.hours === 0 || this.hours === '0') && (this.minutes === 0 || this.minutes === '0')
      && (this.days === 0 || this.days === '0')) {
          this.isErrored = true
        } else {
          this.isErrored = false
        }
      }
    },
    setTouched() {
      this.isTouched = true
      this.setError()
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const validate = () => form.value.validate()

    const reset = () => {
      form.value.reset()
    }

    const account = ref(null)
    const message = ref(null)
    const groups = ref(null)

    const hours = ref(0)
    const minutes = ref(0)
    const days = ref(0)
    const enable = ref(false)

    const submit = async () => {
      if (validate()) {
        const data = {
          account: account.value,
          message: message.value,
          groups: groups.value,
          schedule: `${days.value}:${hours.value}:${minutes.value}`,
          enable: enable.value,
        }
        emit('submit', data)
      }
    }

    return {
      form,

      account,
      groups,
      message,
      hours,
      minutes,
      enable,
      days,
      validate,
      reset,
      simpleRequired,
      submit,
      icons: {
        mdiCheck,
        mdiAlertCircleOutline,
        mdiCalendar,
      },
    }
  },
}
</script>
