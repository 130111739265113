<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Edit Task
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <EditTaskForm
          v-if="task"
          ref="form"
          :task="task"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import { mapActions, mapMutations } from 'vuex'
import ConfirmDialog from '../../ConfirmDialog.vue'
import EditTaskForm from './EditForm.vue'
import { TELEGRAM_MODULE_NAME } from '@/store/modules/telegramStoreModule'

const DEFAULT = {

}

export default {
  components: { ConfirmDialog, EditTaskForm },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    initTask: DEFAULT,
    task: DEFAULT,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initTask, this.task)
    },
  },
  methods: {
    ...mapActions({
      updateTask(dispatch, payload) {
        return dispatch(`${TELEGRAM_MODULE_NAME}/updateTask`, payload)
      },
    }),

    ...mapMutations({
      commitEditTask(commit, payload) {
        return commit(`${TELEGRAM_MODULE_NAME}/EDIT_TASK`, payload)
      },
    }),
    async tryUpdateTask(value) {
      try {
        this.loading = true
        this.commitEditTask({ ...value, loading: true })
        const { data } = await this.updateTask({ task: value })
        this.commitEditTask({ ...data, loading: false })
        this.reset()
        this.$emit('update', data)
        this.$message.success('Task updated successfully')
      } catch (error) {
        this.$message.error(error)
        this.commitEditTask({ ...value, loading: false })
      }
      this.loading = false
    },
    open(task) {
      this.isOpen = true
      this.initTask = cloneDeep(task)
      this.task = cloneDeep(task)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        const data = await this.$refs.form.submit()
        await this.tryUpdateTask(data)
      }
    },
    reset() {
      this.isOpen = false
      this.task = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
